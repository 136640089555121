import { useState, useEffect } from "react";
import { IS_BROWSER } from "~/hooks/common";
import { Paragraph } from "../ui/typography";
import { spTrackWebInteraction } from "~/utils/tracking";

export function CookieSettingLink() {
	const [actualSettingsElement, setActualSettingsElement] =
		useState<HTMLAnchorElement | null>(null);
	const [checkedAgain, setCheckedAgain] = useState<boolean>(false);

	useEffect(() => {
		const element: HTMLAnchorElement | null = IS_BROWSER
			? document.querySelector("a.optanon-toggle-display")
			: null;
		setActualSettingsElement(element);
		const timeout = setTimeout(() => setCheckedAgain(true), 5000);
		return () => timeout && clearTimeout(timeout);
	}, [checkedAgain]);

	const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
		e.preventDefault();
		if (actualSettingsElement) {
			actualSettingsElement.click();
			spTrackWebInteraction({
				object: "consent button",
				action: "click",
				value: "Cookies settings",
			});
		}
	};

	if (actualSettingsElement && checkedAgain) {
		return (
			<Paragraph
				as="li"
				size="body-xsmall"
				className="mt-2 [&:not(:first-child)]:border-l [&:not(:first-child)]:border-l-grey-40"
			>
				<a
					href="#0"
					className="whitespace-nowrap px-3 text-grey-40 hover:underline"
					onClick={handleClick}
				>
					Cookie settings
				</a>
			</Paragraph>
		);
	}
	return null;
}
