import clsx from "clsx";
import React from "react";
import { asLink } from "~/utils/sanity-helpers";
import { MegaNavSection } from "./meganav-section";
import { Link } from "~/components/ui/link";
import { Paragraph } from "~/components/ui/typography";
import type { NavigationDocument } from "~/types/sanity-schema";
import { useLocation } from "@remix-run/react";

interface MegaNavProps {
	navigationData: NavigationDocument;
	variant?: "primary" | "secondary";
}

export function MegaNav({ navigationData, variant = "primary" }: MegaNavProps) {
	const navRef = React.useRef<HTMLDivElement>(null);
	const location = useLocation();

	const navItems = navigationData.items;

	const [activeMenu, setActiveMenu] = React.useState<string | null>(null);

	function handleActiveMenu(id: string) {
		// have to define inside function because
		// `document` is not available in SSR
		const mainNav = document.querySelector("#main-nav");
		if (!mainNav) {
			return;
		}
		mainNav.classList.add("!z-50");
		setActiveMenu(id);
	}

	function handleDisabledMenu() {
		const mainNav = document.querySelector("#main-nav");
		if (!mainNav) {
			return;
		}
		mainNav.classList.remove("!z-50");
		setActiveMenu(null);
	}

	// close menu when location changes
	React.useEffect(() => {
		handleDisabledMenu();
	}, [location.pathname]);

	// close menu when click outside
	React.useEffect(() => {
		function clickOutside(event: MouseEvent) {
			if (!navRef.current?.contains(event.target as Element)) {
				handleDisabledMenu();
			}
		}
		if (activeMenu) {
			document.addEventListener("mouseup", clickOutside);
		} else {
			document.removeEventListener("mouseup", clickOutside);
		}

		return () => document.removeEventListener("mouseup", clickOutside);
	}, [activeMenu]);

	function navSectionClick(id: string) {
		if (activeMenu === id) {
			handleDisabledMenu();
		} else {
			handleActiveMenu(id);
		}
	}

	function navSectionMouseEnter(id: string) {
		handleActiveMenu(id);
	}

	function navSectionMouseLeave(id: string) {
		if (activeMenu === id) {
			handleDisabledMenu();
		}
	}

	const handleEscape = (event: React.KeyboardEvent) => {
		if (event.key === "Escape" && activeMenu) {
			handleDisabledMenu();
		}
	};

	return (
		<div
			onKeyUp={(event) => {
				handleEscape(event);
			}}
			className="relative flex items-center gap-3"
			ref={navRef}
		>
			{navItems
				? navItems.map((navItem, index) => {
						const { title } = navItem;

						if (!title) return null;

						if (navItem._type === "link") {
							const url = asLink(navItem);

							const isActive = url === location.pathname;

							return url ? (
								<Link
									key={index}
									to={url}
									trackingText={title}
									trackingPosition="menu"
									className={clsx(
										"relative z-40",
										{
											"hover:text-primary px-3 py-4 decoration-theme-primary underline-offset-8 hover:underline":
												variant === "primary",
										},
										{
											"hover:text-primary px-3 py-4 decoration-theme-primary underline-offset-8 hover:underline":
												variant === "secondary",
										},
										{
											"text-primary underline decoration-theme-primary underline-offset-8":
												isActive &&
												variant === "secondary",
										},
										{
											"text-primary":
												isActive &&
												variant === "primary",
										}
									)}
								>
									<Paragraph
										as="span"
										size="body-small"
										color="current"
										fontWeight="font-medium"
									>
										{title}
									</Paragraph>
								</Link>
							) : null;
						}

						const id = navItem._key;

						return (
							<MegaNavSection
								navLinkGroups={navItem.navLinkGroups}
								navSubmenuItems={navItem.navSubmenuItems}
								variant={variant}
								key={index}
								id={id}
								open={activeMenu === id}
								onClick={navSectionClick}
								onMouseEnter={navSectionMouseEnter}
								onMouseLeave={navSectionMouseLeave}
								buttonName={title}
							/>
						);
				  })
				: []}
		</div>
	);
}
