import React from "react";
import clsx from "clsx";
import type {
  LoaderFunctionArgs,
  MetaFunction,
  LinksFunction,
  SerializeFrom } from
"@remix-run/node";
import { json } from "@remix-run/node";
import TagManager from "react-gtm-module";
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
  useNavigation } from
"@remix-run/react";
import ReactDOM from "react-dom";
import { GenericErrorBoundary } from "~/components/errors";
import globalStyles from "./styles/app.css";
import tailwindStyles from "./styles/tw.css";
import vendorStyles from "./styles/vendors.css";
import { Footer } from "./components/footer/footer";
import { Navbar } from "./components/navbar";
import { getSocialMetas, defaultMetaData } from "~/utils/seo";
import {
  getDomainUrl,
  isDevelopment,
  removeLangCode,
  removeTrailingSlash,
  getUrl,
  getOrigin } from
"~/utils/misc";
import { getLanguage } from "~/utils/language";
import { useSharedContent } from "./hooks/localization";
import { getSharedContent } from "./queries/localization.server";
import { usePageLoaderData } from "./hooks/common";
import { getFooter } from "./queries/page.server";
import { getPreview } from "~/utils/sanity.server";
import { getNavigation } from "~/queries/navigation.server";
import { NavProvider } from "~/utils/nav-provider";
import {
  getSnowplow,
  getCookieConsent,
  getQualified,
  usePageTracking,
  getAccountEngagement,
  get6Sense } from
"~/utils/tracking";
import { PageLoading } from "./components/page-loading";
import { getDefaultPageCachingHeaders } from "./utils/caching";
import { getEnv } from "./utils/env.server";
import { withSentry } from "@sentry/remix";
import PHProvider from "~/utils/posthog-client";
import { PageBanner, PostHogBanner } from "./components/PageAddOns";
import {
  ExperimentProvider,
  getPosthogBootstrapData,
  getPageTrackingExperimentData } from
"~/utils/experiment";
import type { PosthogBootstrapDataType } from "~/utils/experiment";

const navigationId = "main-menu";

export type LoaderData = SerializeFrom<typeof loader>;

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const locale = getLanguage(request);
  // we only have a site for en and campaign page for de, fr which both footer and navigation are in en
  const fetchLocale = "en";
  const { isPreview } = getPreview(request);

  const [footerLinks, navigationData, sharedContent] = await Promise.all([
  getFooter(fetchLocale),
  getNavigation(fetchLocale, navigationId),
  getSharedContent(fetchLocale)]
  );

  const bootstrapData = await getPosthogBootstrapData(request);
  return json(
    {
      requestInfo: {
        origin: getDomainUrl(request),
        path: new URL(request.url).pathname
      },
      locale,
      footerLinks,
      sharedContent,
      navigationData,
      isPreview,
      ENV: getEnv(isPreview),
      bootstrapData
    },
    {
      headers: getDefaultPageCachingHeaders()
    }
  );
};

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  const requestInfo = data?.requestInfo;

  return getSocialMetas({
    url: getUrl(requestInfo),
    origin: getOrigin(requestInfo),
    title: defaultMetaData.title
  });
};

export const links: LinksFunction = () => {
  return [
  { rel: "preload", href: globalStyles, as: "style" },
  { rel: "preload", href: tailwindStyles, as: "style" },
  { rel: "preload", href: vendorStyles, as: "style" },
  {
    rel: "preload",
    as: "font",
    href: "/fonts/poppins-v20-latin-600.woff2",
    type: "font/woff2",
    crossOrigin: "anonymous"
  },
  {
    rel: "preload",
    as: "font",
    href: "/fonts/inter-v12-latin-regular.woff2",
    type: "font/woff2",
    crossOrigin: "anonymous"
  },
  {
    rel: "preload",
    as: "font",
    href: "/fonts/inter-v12-latin-500.woff2",
    type: "font/woff2",
    crossOrigin: "anonymous"
  },
  { rel: "stylesheet", href: globalStyles },
  { rel: "stylesheet", href: vendorStyles },
  { rel: "stylesheet", href: tailwindStyles },
  {
    rel: "apple-touch-icon",
    sizes: "180x180",
    href: "/favicons/apple-touch-icon.png"
  },
  {
    rel: "icon",
    type: "image/png",
    sizes: "32x32",
    href: "/favicons/favicon-32x32.png"
  },
  {
    rel: "icon",
    type: "image/png",
    sizes: "16x16",
    href: "/favicons/favicon-16x16.png"
  },
  { rel: "icon", href: "/favicon.ico" },
  { rel: "manifest", href: "/site.webmanifest" }];

};

export const renderHrefLang = (
origin: string,
path: string,
locales: string[] = []) =>
{
  const originWithoutTrailingSlash = removeTrailingSlash(origin);
  return (
    <>
			{locales.map((locale) =>
      <link
        key={locale}
        rel="alternate"
        href={`${originWithoutTrailingSlash}/${locale}${path}`}
        hrefLang={locale} />

      )}
			<link
        rel="alternate"
        href={`${originWithoutTrailingSlash}${path}`}
        hrefLang="en" />

			<link
        rel="alternate"
        href={`${originWithoutTrailingSlash}${path}`}
        hrefLang="x-default" />

		</>);

};

if (typeof window !== "undefined" && process.env.NODE_ENV !== "production") {
  const axe = require("@axe-core/react");
  axe(React, ReactDOM, 1000);
}

function App() {
  const {
    locale,
    requestInfo: { origin, path },
    footerLinks,
    isPreview,
    navigationData,
    ENV,
    bootstrapData
  } = useLoaderData<typeof loader>();

  const navRef = React.useRef<HTMLDivElement>(null);
  const [navHeight, setNavHeight] = React.useState(0);

  const pageData = usePageLoaderData();

  const navigation = useNavigation();

  const isNavigating = navigation.state === "loading";

  const canonicalUrl =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pageData && (pageData.data as any)?.seo?.canonicalUrl || path;

  const excludeFromSitemap =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pageData && (pageData.data as any)?.seo?.excludeFromSitemap == true;

  const pathWithoutLangCode = removeLangCode(path);

  const shouldRenderExternalScripts =
  !ENV?.CI && !isDevelopment() && !isPreview;

  // initialize GTM on an effect so it runs after hydration
  React.useEffect(() => {
    if (shouldRenderExternalScripts) {
      const GTM_ID = "GTM-KVSR9J7";

      TagManager.initialize({
        gtmId: GTM_ID
      });
    }
  }, [shouldRenderExternalScripts]);

  React.useEffect(() => {
    if (navRef.current) {
      setNavHeight(navRef.current.offsetHeight);
    }
  }, [navRef]);

  function renderSecondaryNav() {
    const secondaryNavigation = pageData?.data?.secondaryNavigation;

    if (!secondaryNavigation) {
      return null;
    }

    return <Navbar navigationData={secondaryNavigation} lang={locale} />;
  }

  const hasTopBanner = pageData?.data?.infoBanner;
  const { pathname } = useLocation();

  const experiment = getPageTrackingExperimentData({
    pageData,
    posthogBootstrapData: (bootstrapData as PosthogBootstrapDataType),
    path: pathname
  });

  usePageTracking(
    experiment ?
    {
      locale: locale,
      featureFlagName: experiment.featureFlagName,
      experimentVariant:
      typeof experiment.experimentVariant === "string" ?
      experiment.experimentVariant :
      ""
    } :
    {
      locale: locale
    }
  );

  return (
    <html
      lang={locale}
      className={clsx({ "lg:scroll-smooth": !isNavigating })}>

			<head>
				{isPreview || excludeFromSitemap ?
        <meta name="robots" content="noindex" /> :
        null}
				<meta charSet="utf-8" />
				<meta
          name="viewport"
          content="width=device-width,initial-scale=1" />

				{/* Zendesk Federated Search */}
				<meta
          name="zd-site-verification"
          content="40tpboazshgt0ngbgwpr2" />

				<Meta />
				{renderHrefLang(
          origin,
          pathWithoutLangCode,
          pageData?.availableLocales
        )}
				<link
          rel="canonical"
          href={`${removeTrailingSlash(origin)}${canonicalUrl}`} />

				<Links />
				{shouldRenderExternalScripts ?
        <>
						<script
            async
            dangerouslySetInnerHTML={{ __html: getSnowplow }} />

						<script
            async
            src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
            data-domain-script="0623fbc6-a463-4822-a7a4-fdb5afcc3afb"
            data-document-language="true" />

						<script
            async
            dangerouslySetInnerHTML={{
              __html: getAccountEngagement
            }} />

						<script
            async
            dangerouslySetInnerHTML={{
              __html: getCookieConsent
            }} />

						<script
            async
            dangerouslySetInnerHTML={{
              __html: getQualified
            }} />

						<script
            async
            src="https://js.qualified.com/qualified.js?token=rSyShDaLFhNDinw3">
          </script>
						<script
            async
            dangerouslySetInnerHTML={{
              __html: get6Sense
            }} />

					</> :
        null}
			</head>
			<body className="text-primary bg-primary font-body">
				<PageLoading />
				{isDevelopment() && !isPreview ?
        <div className="text-primary fixed bottom-3 right-3 z-50 bg-green-0 p-1 text-center opacity-60">
						Development Mode
					</div> :
        null}
				<PHProvider
          bootstrapData={(bootstrapData as PosthogBootstrapDataType)}>

					{hasTopBanner ?
          <PageBanner data={pageData?.data} /> :

          <PostHogBanner />}

					<div
            className="sticky top-0 z-40"
            id="main-nav"
            ref={navRef}>

						{navigationData ?
            <Navbar
              navigationData={navigationData}
              lang={locale} /> :

            null}
						{renderSecondaryNav()}
					</div>

					<NavProvider navHeight={navHeight}>
						<ExperimentProvider
              posthogBootstrapData={(
              bootstrapData as PosthogBootstrapDataType)}>


							<Outlet />
						</ExperimentProvider>
					</NavProvider>
				</PHProvider>
				<Footer footerLinks={footerLinks} />
				<ScrollRestoration />
				<Scripts />
				<script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(ENV)};`
          }} />

				<LiveReload />
			</body>
		</html>);

}

export default withSentry(App);

// This is the root ErrorBoundary which is the last catch
// all other page error should be caught by index route which will include
// the navigation and footer
export function ErrorBoundary() {
  const { t } = useSharedContent();

  return (
    <html>
			<head>
				<title>
					{t(
            "errorTitle",
            "We're currently experiencing some technical difficulties"
          )}
				</title>
				<Links />
			</head>
			<body className="text-primary bg-primary">
				<GenericErrorBoundary />
				<Scripts />
			</body>
		</html>);

}